import React, { useState, useEffect } from "react";
import { List, Skeleton, Avatar } from "antd";
import FetchDataUpdate from "./fetchDataUpdate";
import { useSelector, useDispatch } from "react-redux";
import { getCoins } from "../../slices/CoinsSlice";
import { useNavigate } from "react-router-dom";
import Base from "../Base";
import "./spot.scss";

export default (props) => {
  const history = useNavigate();
  const { coins, isLoading } = useSelector((state) => state.coinsSlice);
  const [OrderType, setOrderType] = React.useState("SELL");
  const dispatch = useDispatch();
  const [Coins, setCoins] = React.useState([]);
  const [coinsData, setCoinsData] = React.useState([]);
  const [SelectedCoins, setSelectedCoins] = React.useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const inputRef = React.useRef(null);
  const [priceColors, setPriceColors] = useState({});
  const [update, setUpdate] = useState({});

  //get coins
  useEffect(() => {
    dispatch(getCoins()).then((response) => setCoins(response.payload.data));
  }, []);

  useEffect(() => {
    if (coinsData.length > 0) {
      const searchTerm = inputRef.current.value.toUpperCase();

      // Use the searchTerm to filter the coinsData
      setFilteredData(
        coinsData.filter((coin) =>
          coin.symbol.toUpperCase().includes(searchTerm)
        )
      );
    }
  }, [coinsData]);

  const concatenatedString = Coins?.join("%2C");

  const updatePriceColors = (previousData, newData) => {
    const newPriceColors = { ...priceColors };
    newData.forEach((coin) => {
      const previousPrice = previousData.find((c) => c.symbol === coin.symbol);
      if (previousPrice) {
        newPriceColors[coin.symbol] =
          coin.price > previousPrice.price
            ? "green"
            : coin.price < previousPrice.price
            ? "red"
            : priceColors;
      } else {
        newPriceColors[coin.symbol] = "grey";
      }
    });
    setPriceColors(newPriceColors);
  };
  FetchDataUpdate(
    concatenatedString,
    coinsData,
    setCoinsData,
    setUpdate,
    update,
    coins,
    updatePriceColors
  );

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toUpperCase();

    // Use the searchTerm to filter the coinsData
    setFilteredData(
      coinsData.filter((coin) => coin.symbol.toUpperCase().includes(searchTerm))
    );

    setCoinsData(filteredData);

    // Show/hide coins based on the search term
    Coins.forEach((coin) => {
      const element = document.getElementById("div" + coin);
      if (element) {
        element.style.display = coin.toUpperCase().includes(searchTerm)
          ? "block"
          : "none";
      }
    });
  };

  const handleChecked = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelectedCoins((prevSelectedCoins) => {
      if (isChecked) {
        // If checked, add the coin to the array
        return [...prevSelectedCoins, value];
      } else {
        // If unchecked, remove the coin from the array
        return prevSelectedCoins.filter((coin) => coin !== value);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (SelectedCoins.length === 0) {
      // No coins selected, you can handle this case as needed, like showing an error message
      alert("Please select at least one coin.");
    } else {
      const params = new URLSearchParams({
        orderType: OrderType,
        coins: SelectedCoins,
      });
      history("/calculation?" + params.toString());
    }
  };

  function formatNumberWithTwoDecimals(price) {
    // Remove trailing zeros after the decimal point
    price = price.replace(/\.?0+$/, "");

    // Ensure there are always two digits after the decimal point
    if (price.indexOf(".") === -1) {
      price += ".00";
    } else if (price.indexOf(".") === price.length - 2) {
      price += "0";
    }

    return price;
  }

  return (
    <Base>
      <div
        className="modal1"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <form
            className="form"
            action="/calculation"
            method="get"
            onSubmit={handleSubmit}
          >
            <div className="modal-content">
              <div className="modal-header">
                <div className="headerButtons">
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <input
                        type="hidden"
                        name="order_type"
                        value={OrderType}
                      />
                      <button
                        className={
                          OrderType == "SELL" ? "nav-link active" : "nav-link"
                        }
                        id="nav-home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-home"
                        type="button"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                        onClick={() => setOrderType("SELL")}
                      >
                        Sell
                      </button>
                      <button
                        className={
                          OrderType == "BUY" ? "nav-link active" : "nav-link"
                        }
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-profile"
                        type="button"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                        onClick={() => setOrderType("BUY")}
                      >
                        Buy
                      </button>
                    </div>
                  </nav>
                </div>
                <div className="searchbar">
                  <div>
                    <input
                      type="text"
                      placeholder="Search.."
                      name="search"
                      onChange={handleSearch}
                      style={{ width: "100%" }}
                      ref={inputRef}
                    />
                  </div>
                </div>
              </div>

              <hr />

              <div className="modal-body" style={{ maxHeight: "50vh" }}>
                <div className="container-fluid" id="coinsList">
                  <div onSubmit={(e) => e.preventDefault()}>
                    <List
                      className="demo-loadmore-list"
                      loading={isLoading}
                      itemLayout="horizontal"
                      dataSource={
                        filteredData.length > 0 ? filteredData : coinsData
                      }
                      renderItem={(coin, index) => (
                        <List.Item
                          className="list-item"
                          actions={[
                            <input
                              style={{ cursor: "pointer" }}
                              id={coin}
                              type="checkbox"
                              name="coins[]"
                              value={coinsData.length > 0 ? coin.symbol : ""}
                              onChange={handleChecked}
                              defaultChecked={
                                SelectedCoins?.includes(
                                  coinsData.length > 0 ? coin.symbol : ""
                                )
                                  ? true
                                  : false
                              }
                            />,
                          ]}
                        >
                          <Skeleton avatar title={false} loading={false} active>
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  icon={
                                    <img
                                      src={`/asset/icon/${coin.symbol.replace('USDT', '').toLowerCase()}.png`}
                                      alt={`Avatar for ${coin.symbol}`}
                                      onError={(e) => {
                                        e.target.src = '/asset/images/bitcoinLogo.png'; // Provide the path to your fallback image
                                        e.target.onerror = null; // Prevent infinite loop in case fallback image also fails
                                      }}
                                    />
                                  }
                                />
                              }
                              title={
                                <span>
                                  {`${index + 1}. ${
                                    coinsData.length > 0 ? coin.symbol.replace('USDT', '') : ""
                                  }`}
                                </span>
                              }
                              description={
                                <span
                                  style={{
                                    color: `${priceColors[coin.symbol]}`,
                                  }}
                                >
                                  {coinsData.length > 0
                                    ? coin.price
                                    : "N/A"}
                                </span>
                              }
                            />
                          </Skeleton>
                        </List.Item>
                      )}
                    />
                  </div>
                </div>
              </div>
              <hr />

              <div className="modal-footer">
                <a href="#">
                  {" "}
                  <button
                    type="submit"
                    className="btn"
                    style={{ borderRadius: 10 }}
                  >
                    Submit
                  </button>
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Base>
  );
};
