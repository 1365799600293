import React from "react"
import "./api.scss"
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '../../utils/LoadingButton';
import { saveApiKeys } from "../../slices/SaveApiKeySlice";
import { postLogout, setApiKey } from "../../slices/LoginSlice";
import { ToastContainer } from "react-toastify";

export default function () {
  const dispatch = useDispatch();
  const { login } = useSelector((state) => state.loginSlice);
  const [Alertt, setAlertt] = React.useState(false);
  
  if (!Alertt && login.api_keys && login.api_keys_status == "INVALID") {
		alert(
			'Please re-enter your api keys and "Enable Spot & Margin Trading" Permission in Binance API Management.'
		);
		setAlertt(true);
	}
  const keys = useSelector((state) => state.saveApiKeySlice);
  const handleSubmit = (e) => {
    e.preventDefault();
    const dispatch_result = dispatch(
			saveApiKeys({
				api_key: e.target.api_key.value,
				api_secret: e.target.api_secret.value,
				testing_mode: e.target.testing_mode.checked,
			})
		).then(res => {
      if(res?.payload?.success){
        // dispatch(setApiKey());
      }
    });
  }
    return (
			<div className="container-fluid" id="apiloginForm">
				<ToastContainer />
				<a
					className="navbar-brand"
					href="#"
					style={{ position: "absolute", right: "25px", top: "15px" }}
				>
					<i
						className="fa fa-sign-out"
						title="Logout"
						style={{ fontSize: "20px" }}
						onClick={() => dispatch(postLogout())}
					></i>
				</a>
				<form action="" onSubmit={handleSubmit}>
					<div className="formHeading">
						<h2 className="heading">Enter Binance Api Keys</h2>
					</div>
					<div className="mb-3 field">
						<label htmlFor="api_key" className="form-label">
							Enter API key
						</label>
						<input
							type="text"
							className="form-control"
							id="api_key"
							name="api_key"
							aria-describedby="emailHelp"
							required
						/>
					</div>

					<div className="mb-3 field">
						<label htmlFor="api_secret" className="form-label">
							Enter API Secret
						</label>
						<input
							type="text"
							className="form-control"
							id="api_secret"
							name="api_secret"
							aria-describedby="emailHelp"
							required
						/>
					</div>
					<div>
						<img src="/asset/images/enble-spot-tranding.svg" alt="" />
					</div>
					<div className="mb-3 field">
						<a
							href="https://testnet.binance.vision/"
							target="_blank"
							style={{ fontSize: "small" }}
						>
							Click here to generate testing keys if you want testing mode.
						</a>
						<label
							htmlFor="testing_mode"
							className="form-label d-flex align-items-center"
						>
							<input
								type="checkbox"
								className="me-2"
								id="testing_mode"
								name="testing_mode"
								aria-describedby="emailHelp"
								value="1"
							/>
							Testing Mode
						</label>
					</div>

					<div className="loginButton">
						<button
							type="submit"
							className="btn"
							disabled={keys.isLoading ? true : false}
						>
							{keys.isLoading ? <LoadingButton /> : "Submit"}
						</button>
						<a href="#"> </a>
					</div>
				</form>
			</div>
		);
}