import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../utils/Axios";

const initialState = {
	orders: {},
	isLoading: false,
	errors: {},
};

export const getDashboardData = createAsyncThunk("orders", async ( dates, thunk) => {
	let startDate =  dates.startDate
	let endDate = dates.endDate
	try {
		const orders = await Axios.get("/dashboard/data", {
            params: { startDate, endDate }, // Pass startDate and endDate as parameters
          });

		return {
			success: true,
			data: orders?.data?.orders,
			message: "",
			error: "",
		};
	} catch (error) {
		return thunk.rejectWithValue({
      success: false,
			data: [],
      message: '',
			error: "Something went wrong with orders.",
		});
	}
});

const OrdersSlice = createSlice({
	name: "OrdersSlice",
	initialState,
	reducers: {
		emptyOrders: (state) => {
			state.orders = {};
		},
	},
	extraReducers: {
		[getDashboardData.pending]: (state) => {
			state.isLoading = true;
		},
		[getDashboardData.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.orders = payload;
		},
		[getDashboardData.rejected]: (state) => {
			state.isLoading = false;
		},
	},
});

export default OrdersSlice.reducer;
export const { emptyOrders } = OrdersSlice.actions;
