import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../utils/Axios";

const initialState = {
	userDetail: {},
	isLoading: false,
	errors: {},
};

export const getUserDetail = createAsyncThunk(
	"userDetail",
	async (payload, thunk) => {
		try {
			const userDetail = await Axios.get("/admin/users/" + payload);
			return userDetail?.data?.data?.length ? userDetail?.data?.data[0] : {};
		} catch (error) {
			console.log(error);
			return thunk.rejectWithValue("Something went wrong with userDetail.");
		}
	}
);

const UserDetailSlice = createSlice({
	name: "UserDetailSlice",
	initialState,
	reducers: {
		emptyUserDetail: (state) => {
			state.userDetail = {};
		},
	},
	extraReducers: {
		[getUserDetail.pending]: (state) => {
			state.isLoading = true;
		},
		[getUserDetail.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.userDetail = payload.data;
		},
		[getUserDetail.rejected]: (state) => {
			state.isLoading = false;
		},
	},
});

export default UserDetailSlice.reducer;
export const { emptyUserDetail } = UserDetailSlice.actions;
