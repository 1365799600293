import React from 'react';
import Navbar from './navbar';
import Sidebar from './sidebar';
import "../../asset/scss/user.scss"
import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from "react-toastify";

const currentTab = (location, currentPath) => {

  if (currentPath === "/admin" && location.pathname !== currentPath) {
    return "";
  }
  if (location.pathname.indexOf(currentPath) !== -1) {
    return "active";
  }
}

export default ({history, children}) => {
  const { login } = useSelector(state => state.adminLoginSlice);
  
  const location = useLocation();
  
  return (
		<>
			{login?.isAuth ? (
				<>
					<Navbar />
					<Sidebar currentTab={currentTab} />
					<ToastContainer />
					<div className="content">{children}</div>
				</>
			) : (
				<Navigate to="/admin" />
			)}
		</>
	);
}