import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Axios from "../utils/Axios";

const initialState = {
  wallet: {},
  isLoading: false,
  errors: {}
}

export const getWallet = createAsyncThunk('wallet', async (data, thunk) => {

  // const [page, searchQuery] = data;
  console.log(data);
  try {
    const wallet = await Axios.get("/wallet", {
      params: { page: data.page, search: data.searchQuery }, 
    });
    
		return {
			success: true,
			data: wallet?.data,
			message: "",
			error: "",
		};
  } catch (error) {
    console.log(error);
    return thunk.rejectWithValue('Something went wrong with wallet.');
  }
})

const WalletSlice = createSlice({
  name: 'WalletSlice',
  initialState,
  reducers: {
    emptyWallet: (state) => {
      state.wallet = {}
    }
  },
  extraReducers: {
    [getWallet.pending]: (state) => {
      state.isLoading = true;
    },
    [getWallet.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.wallet = payload;
    },
    [getWallet.rejected]: (state) => {
      state.isLoading = false;
    }
  }
})

export default WalletSlice.reducer;
export const { emptyWallet } = WalletSlice.actions;