import { useEffect } from "react";
import Axios from "../../utils/Axios";

export default function FetchDataUpdate(
  concatenatedString,
  coinsData,
  setCoinsData,
  setUpdate,
  update,
  coins,
  updatePriceColors
) {
  useEffect(() => {
    // Define a function to fetch data
    const fetchData = () => {
      if (concatenatedString.length > 0) {
        Axios.get(`/getCoinsPrice?symbols=${concatenatedString}`)
          .then((response) => {
            updatePriceColors(coinsData, response.data);
            setCoinsData(response.data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    };

    // Call the fetchData function immediately
    fetchData();

    setTimeout(() => {
      setUpdate({ ...update });
    }, 4000);
    // Return a cleanup function to clear the interval when the component unmounts
  }, [coins, update]);
}
