import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../utils/Axios";

const initialState = {
	orders: {},
	isLoading: false,
	errors: {},
};

export const getOrders = createAsyncThunk("orders", async (page, thunk) => {
	try {
		const orders = await Axios.get("/orders/all", {
            params: { page }, 
          });
console.log(orders);
		return {
			success: true,
			data: orders?.data,
			message: "",
			error: "",
		};
	} catch (error) {
		return thunk.rejectWithValue({
      success: false,
			data: [],
      message: '',
			error: "Something went wrong with orders.",
		});
	}
});

const OrdersSlice = createSlice({
	name: "OrdersSlice",
	initialState,
	reducers: {
		emptyOrders: (state) => {
			state.orders = {};
		},
	},
	extraReducers: {
		[getOrders.pending]: (state) => {
			state.isLoading = true;
		},
		[getOrders.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.orders = payload;
		},
		[getOrders.rejected]: (state) => {
			state.isLoading = false;
		},
	},
});

export default OrdersSlice.reducer;
export const { emptyOrders } = OrdersSlice.actions;
