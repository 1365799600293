import React, {useEffect} from "react";
import { postLogin } from "../../slices/LoginSlice";
import "./login.scss";
import {useDispatch, useSelector} from 'react-redux';
import { useLocation } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import Axios from "../../utils/Axios";
import { Link } from "react-router-dom";

export default function() {

  const dispatch = useDispatch();
  const location = useLocation();

  // useEffect(() => {
  //   toast('hello! i am working');
  // }, [])

  const user = useSelector(state => state.loginSlice)
  const errors = user?.errors;

  useEffect(() => {
    // if(user.login){
    //   if(user.login.api_keys){
    //     //redirect to wallet
    //     location.redirect('/save-api-keys');
    //   } else {
    //     //redirect to save api keys form
    //     location.redirect('/wallet');
    //   }
    // }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(postLogin({email: event.target.email.value, password: event.target.password.value}));
  }

  const LoadingButton = () => {
    return <span className="spinner-grow spinner-grow-sm"></span>;
  }

    return (
			<div className="container-fluid" id="loginForm">
				<ToastContainer />
				<form onSubmit={handleSubmit}>
					<div className="formHeading">
						<h2 className="heading">Login</h2>
					</div>

					<div className="mb-3 field">
						<label htmlFor="exampleInputEmail1" className="form-label">
							Email address
						</label>
						<input
							type="email"
							className="form-control"
							id="exampleInputEmail1"
							aria-describedby="emailHelp"
							name="email"
							required
						/>
					</div>
					<div className="mb-3 field">
						<label htmlFor="exampleInputPassword1" className="form-label">
							Password
						</label>
						<input
							type="password"
							className="form-control"
							name="password"
							id="exampleInputPassword1"
							required
						/>
					</div>

					<div className="loginButton d-grid">
						<button
							type="submit"
							className="btn"
							disabled={user.isLoading ? true : false}
						>
							{user.isLoading ? <LoadingButton /> : "Submit"}
						</button>
					</div>
					<div className="d-grid justify-content-center">
						<Link className="mt-4" to="/forgot-password">
							Forgot Password
						</Link>
					</div>
				</form>
			</div>
		);
}