import Axios from "../../../utils/Axios";
import React from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		zIndex: "2000",
		width: "100%",
		maxWidth: "750px",
	},
};

Modal.setAppElement("#yourAppElement");

export default (props) => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		// dispatch(getCoins()).then((response) => setCoins(response.payload.data));
	}, []);

	let subtitle;
	const [modalIsOpen, setIsOpen] = React.useState(false);

	const isOpen = () => {
		console.log("click");
	};

	React.useEffect(() => {
		if (props?.modalState) {
			openModal();
		}
	}, [props?.modalState]);

	React.useEffect(() => {
		props.closed();
    console.log(modalIsOpen);
	}, [modalIsOpen]);

	function openModal() {
		setIsOpen(true);
	}
	function afterOpenModal() {
		// references are now sync'd and can be accessed.
		// subtitle.style.color = "#f00";
	}
	function closeModal() {
		setIsOpen(false);
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
    let data = {
      email: e.target.elements.email.value,
      password: e.target.elements.password.value,
      password2: e.target.elements.password.value,
      first_name: e.target.elements.first_name.value,
      last_name: e.target.elements.last_name.value,
    }
    const response = await Axios.post("/admin/users/store", data)
			.then((ress) => {
				closeModal();
				props.updateUsers();
			})
			.catch((err) => toast());
    
		// closeModal();

	};

	return (
		<Modal
			isOpen={modalIsOpen}
			onAfterOpen={afterOpenModal}
			onRequestClose={closeModal}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<div
				className="modal1"
				id="exampleModal"
				tabIndex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<form onSubmit={handleSubmit}>
						<div className="modal-content">
							<div className="modal-header">
								Create A New User
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={() => closeModal()}
								></button>
							</div>

							<hr />

							<div className="modal-body" style={{ overflow: "unset" }}>
								<div className="container-fluid" id="coinsList">
									<div className="row">
										<div className="form-group">
											<label htmlFor="email">Email</label>
											<input
												type="email"
												id="email"
												name="email"
												className="form-control"
												required
											/>
										</div>
										<div className="form-group">
											<label htmlFor="first_name">First Name</label>
											<input
												type="text"
												id="first_name"
												name="first_name"
												className="form-control"
												required
											/>
										</div>
										<div className="form-group">
											<label htmlFor="last_name">Last Name</label>
											<input
												type="text"
												id="last_name"
												name="last_name"
												className="form-control"
												required
											/>
										</div>
										<div className="form-group">
											<label htmlFor="password">Password</label>
											<input
												type="text"
												id="password"
												name="password"
												minLength="8"
												className="form-control"
												required
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="modal-footer">
								<a href="#">
									{" "}
									<button type="submit" className="btn">
										Submit
									</button>
								</a>
							</div>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};
