import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import Axios from '../../utils/Axios';
import { toast } from "react-toastify";

const initialState = {
	login: {},
	isLoading: false,
	errors: {},
};

export const postAdminLogin = createAsyncThunk(
	"adminLogin",
	async (payload, thunkAPI) => {
		try {
			const res = await Axios.post("/admin/login", payload);
			return res.data;
		} catch (error) {
			return thunkAPI.rejectWithValue("Something went wrong in login");
		}
	}
);

export const postAdminLogout = createAsyncThunk(
	"adminLogout",
	async (payload, thunkAPI) => {
		try {
			const res = await Axios.post("/admin/logout",
				{}
			);
			return res.data;
		} catch (error) {
			return thunkAPI.rejectWithValue("something went wrong in logout");
		}
	}
);

// const logout = createAction('logout');

const loginSlice = createSlice({
	name: "AdminLogin",
	initialState,
	reducers: {
		login: (state, { payload }) => {
			state.login = payload;
		},
		logout: (state) => {
			state.login = {};
		},
		setApiKey: (state) => {
			state.login.api_keys = true;
		},
	},
	extraReducers: {
		[postAdminLogin.pending]: (state) => {
			state.isLoading = true;
			state.errors = {};
		},
		[postAdminLogin.fulfilled]: (state, action) => {
			state.isLoading = false;

			if (action.payload.isAuth == false) {
				state.errors = action.payload;
				toast.error(state.errors.message);
			} else {
				toast.success("Login Success");
				state.login = action.payload;
			}
			// state.login = action.payload;
		},
		[postAdminLogin.rejected]: (state, action) => {
			state.isLoading = false;
		},
		[postAdminLogout.pending]: (state) => {
			state.isLoading = true;
			state.errors = {};
		},
		[postAdminLogout.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.login = {};
      document.cookie = "adminAuth=;";
		},
		[postAdminLogout.rejected]: (state, action) => {
			state.isLoading = false;
		},
	},
});

export const { login, logout, setApiKey } = loginSlice.actions;
// export const loginAction = loginSlice.actions;

export default loginSlice.reducer;
