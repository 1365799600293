import axios from 'axios';

const defaultOptions = {
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 10000,
  withCredentials: true,
	headers: {
		"Content-Type": "application/json",
	},
};
let instance = axios.create(defaultOptions);
instance.interceptors.response.use(
	(response) => {
		return response;
	},
	function (error) {
    if(error.response.status == 401){

      if ( error.config.url.indexOf("/admin") !== -1 ) {
        localStorage.setItem("persist:adminLogin", "");
        document.cookie = "adminAuth=;";
        alert("Your session has been expired! Please Login Again.")
        window.location.href="/admin";
      } else {
        if (
					error.response.data.message ==
					"Invalid API-key, IP, or permissions for action."
				) {

          alert(error.response.data.message);
				} else {
          alert("Your session has been expired! Please Login Again.")
        }
          
        localStorage.setItem("persist:login", "");
        document.cookie = "userAuth=;";
        window.location.href = "/";
      }
    }
		return Promise.reject(error);
	}
);

export default instance;