import React from "react";
import "./sidebar.scss";
import { Link, useLocation } from "react-router-dom";
import Modal from "../Modal";
import ChangePassword from "../changePassword";

export default function (props) {
  const location = useLocation();
  const [OpenModal, setOpenModal] = React.useState(false);
  const [OpenChangePasswordModal, setOpenChangePasswordModal] =
    React.useState(false);

  const isOpen = () => {
    setOpenModal(true);
  };

  const closed = () => {
    setOpenModal(false);
  };

  const isChangePasswordOpen = () => {
    setOpenChangePasswordModal(true);
  };

  const closedChangePassword = () => {
    setOpenChangePasswordModal(false);
  };

  const cancelButn = {
    position: "fixed",
    cursor: "pointer",
    backgroundColor: "rgb(78, 135, 108)",
    zIndex: "1111",
    left: "227px",
    top: "82px",
    fontSize: "20px",
    color: "white",
    padding: "0px 5px",
    transition: "all 0.5s ease",
  };
  return (
    <>
      <div id="SidebarComponent">
        <input type="checkbox" id="check" />
        <div className="sidebar">
          <header>My App</header>
          <ul>
            <li className={props.currentTab(location, "/dashboard")}>
              <Link to="/dashboard" className="hvr-sweep-to-right">
              <i className="fa fa-dashboard"></i>
                <span>Dashboard</span>
              </Link>
            </li>
            <li className={props.currentTab(location, "/")}>
              <Link to="/" className="hvr-sweep-to-right" href="wallet.html">
                <i className="fa-solid fa-wallet"></i>
                <span>Wallet</span>
              </Link>
            </li>
            <li className={props.currentTab(location, "/history")}>
              <Link className="hvr-sweep-to-right " to="/history">
                <i className="fa-solid fa-clock-rotate-left"></i>
                <span>History</span>
              </Link>
            </li>
            <li className={props.currentTab(location, "/spot")}>
              <Link className="hvr-sweep-to-right " to="/spot">
                <i className="fa-solid  fa-dollar-sign"></i>
                <span>Spot Trading</span>
              </Link>
            </li>
            <li>
              <a
                className="hvr-sweep-to-right"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={isOpen}
                href="#"
              >
                {" "}
                <i className="fa-solid fa-trademark"></i>
                <span> Open Trade</span>
              </a>
            </li>
            <li>
              <a
                className="hvr-sweep-to-right"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal1"
                onClick={isChangePasswordOpen}
                href="#"
              >
                {" "}
                <i className="fa-solid fa-user"></i>
                <span>Change Password</span>
              </a>
            </li>
          </ul>
        </div>
        <Modal modalState={OpenModal} isOpen={isOpen} closed={closed} />
        <ChangePassword
          modalState={OpenChangePasswordModal}
          isOpen={isChangePasswordOpen}
          closed={closedChangePassword}
        />
      </div>
    </>
  );
}
