import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../utils/Axios";

const initialState = {
	users: {},
	isLoading: false,
	errors: {},
};

export const getUsers = createAsyncThunk("users", async (payload, thunk) => {
	try {
		const users = await Axios.get("/admin/users/all");
		return users?.data?.data;
	} catch (error) {
		console.log(error);
		return thunk.rejectWithValue("Something went wrong with users.");
	}
});

const UsersSlice = createSlice({
	name: "UsersSlice",
	initialState,
	reducers: {
		emptyUsers: (state) => {
			state.users = {};
		},
	},
	extraReducers: {
		[getUsers.pending]: (state) => {
			state.isLoading = true;
		},
		[getUsers.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.users = payload;
		},
		[getUsers.rejected]: (state) => {
			state.isLoading = false;
		},
	},
});

export default UsersSlice.reducer;
export const { emptyUsers } = UsersSlice.actions;
