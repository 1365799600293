import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../utils/Axios";

const initialState = {
	orders: {},
	isLoading: false,
	errors: {},
};

export const getOrders = createAsyncThunk("orders", async (payload, thunk) => {
	try {
		const orders = await Axios.get("/admin/users/" + payload + "/orders/all");

		return orders?.data?.data;
	} catch (error) {
		console.log(error);
		return thunk.rejectWithValue("Something went wrong with orders.");
	}
});

const OrdersSlice = createSlice({
	name: "OrdersSlice",
	initialState,
	reducers: {
		emptyOrders: (state) => {
			state.orders = {};
		},
	},
	extraReducers: {
		[getOrders.pending]: (state) => {
			state.isLoading = true;
		},
		[getOrders.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.orders = payload;
		},
		[getOrders.rejected]: (state) => {
			state.isLoading = false;
		},
	},
});

export default OrdersSlice.reducer;
export const { emptyOrders } = OrdersSlice.actions;
