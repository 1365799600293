import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../utils/Axios";

const initialState = {
	orderDetails: {},
	isLoading: false,
	errors: {},
};

export const getOrderDetails = createAsyncThunk("orderDetails", async (payload, thunk) => {
  
	try {
		const orderDetails = await Axios.get("/orders/" + payload + "/all");
		return orderDetails?.data?.data;
	} catch (error) {
		console.log(error);
		return thunk.rejectWithValue("Something went wrong with orderDetails.");
	}
});

const OrderDetailsSlice = createSlice({
	name: "OrderDetailsSlice",
	initialState,
	reducers: {
		emptyOrderDetails: (state) => {
			state.orderDetails = {};
		},
	},
	extraReducers: {
		[getOrderDetails.pending]: (state) => {
			state.isLoading = true;
		},
		[getOrderDetails.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.orderDetails = payload.data;
		},
		[getOrderDetails.rejected]: (state) => {
			state.isLoading = false;
		},
	},
});

export default OrderDetailsSlice.reducer;
export const { emptyOrderDetails } = OrderDetailsSlice.actions;
