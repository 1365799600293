import React from "react";
import "./calculation.scss";
import Base from "../Base";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../../utils/Axios";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import axios from "axios";
import LoadingButton from "../../utils/LoadingButton";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getWallet } from "../../slices/WalletSlice";

export default function () {
  // let {orderType, coins} = useParams();
  const navigate = useNavigate();
  const [Wallet, setWallet] = React.useState([]);
  const dispatch = useDispatch();
  const { wallet } = useSelector((state) => state.walletSlice);
  const [Coins, setCoins] = React.useState([]);

  const [IsLoading, setIsLoading] = React.useState(true);

  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);

  const orderType = urlParams.get("orderType");
  let coins = urlParams.get("coins");
  let arrayCoins = coins.split(",");
  let quantities;
  let arrayQuantities = [];
  if (urlParams.get("quantities")) {
    quantities = urlParams.get("quantities");
    arrayQuantities = quantities.split(",");
  }

  const symbols = new URLSearchParams({
    symbols: arrayCoins,
  });

  let coinArrayOfObjects;
  React.useEffect(() => {
    setIsLoading(true);
    Axios.get("/getCoinsPrice?" + symbols.toString()).then((response) => {
      coinArrayOfObjects = arrayCoins?.map((coin, index) => {
        let main = {};
        main.symbol = coin;
        if (arrayQuantities.length) {
          main.quantity = arrayQuantities[index];
        } else {
          main.quantity = "";
        }
        main.limit_price = response.data?.find(
          (item) => item.symbol == coin
        ).price;
        return main;
      });
      setCoins(coinArrayOfObjects);

      setIsLoading(false);
    });
  }, [coins]);

  React.useEffect(() => {
    setCoins(coinArrayOfObjects);
  }, [coins]);

  React.useEffect(() => {
    dispatch(getWallet({page: 1, searchQuery: ''})).then((res) => setWallet(res.payload));
  }, []);

  const handleSubmit = async (data, setErrors) => {
    // e.preventDefault();

    let reqData = { ...data, orderType };
    setIsLoading(true);
    const response = await Axios.post("/openAnOrder", reqData).catch((err) => {
      if (err.response.status == 422) {
        let errors = err.response.data.errors;

        setErrors({ errors });

        toast.error("Please check errors.");
      } else {
        toast.error("Server Error! Please contact Administrator.");
      }
    });
    setIsLoading(false);
    toast.success(response.data.message);
    setTimeout(() => {
      navigate("/history");
    }, 3000);
  };

  const handleDelete = (coins, coin) => {
    let quantity;
    let limit_price;
    let total;
    let grandTotal = 0;
    const remainingCoins = coins.filter(
      (coin2) => coin2.symbol !== coin.symbol
    );
    remainingCoins.forEach((coin1) => {
      quantity = parseFloat(
        document.getElementById("quantity_" + coin1.symbol).value
      );
      limit_price = parseFloat(
        document.getElementById("limit_price_" + coin1.symbol).value
      );
      if (limit_price) {
        total = quantity * limit_price;
        console.log({ total }, coin1.symbol);
        document.getElementById("total_" + coin1.symbol).innerText = total;
        grandTotal += total;
      }
    });
    document.getElementById("grandTotal").innerText = grandTotal.toFixed(4);
    // for (
    // 	let index = 0;
    // 	index < document.getElementsByClassName("totals").length;
    // 	index++
    // ) {
    // 	grandTotal += isNaN(
    // 		document.getElementsByClassName("totals")[index].innerText
    // 	)
    // 		? 0
    // 		: +document.getElementsByClassName("totals")[index].innerText;
    // }
    // document.getElementById("grandTotal").innerText = grandTotal.toFixed(4);
  };

  const handleKeyUpQuantity = (e, coin) => {
    document.getElementById("error_quantity_" + coin.symbol).innerText = "";
    let maxUSDT = parseFloat(document.getElementById("max_USDT").innerText);
    let currentQuantity = parseFloat(e.target.value);
    let maxQuantity = parseFloat(
      document.getElementById("max_" + coin.symbol).innerText
    );

    if (orderType == "SELL" && currentQuantity > maxQuantity) {
      document.getElementById("quantity_" + coin.symbol).value = 0;
      document.getElementById("error_quantity_" + coin.symbol).innerText =
        "You can't sell quantity more than you have.";
      return;
    }

    let price =
      document.getElementById("limit_price_" + coin.symbol).value ||
      document.getElementById("limit_price_span_" + coin.symbol).text;

    let total = e.target.value * price;

    document.getElementById("total_" + coin.symbol).innerText = isNaN(total)
      ? ""
      : total;

    let totalSymbolPrice = parseFloat(
      document.getElementById("total_" + coin.symbol).innerText
    );

    if (orderType == "BUY" && totalSymbolPrice > maxUSDT) {
      document.getElementById("quantity_" + coin.symbol).value = 0;
      document.getElementById("total_" + coin.symbol).innerText = 0;
      document.getElementById("error_quantity_" + coin.symbol).innerText =
        "Your order amount should be less than available amount.";
    }

    let grandTotal = 0;
    for (
      let index = 0;
      index < document.getElementsByClassName("totals").length;
      index++
    ) {
      grandTotal += isNaN(
        document.getElementsByClassName("totals")[index].innerText
      )
        ? 0
        : +document.getElementsByClassName("totals")[index].innerText;
    }
    document.getElementById("grandTotal").innerText = grandTotal.toFixed(4);

    grandTotal = parseFloat(grandTotal);

    if (orderType == "BUY" && grandTotal > maxUSDT) {
      document.getElementById("quantity_" + coin.symbol).value = 0;
      document.getElementById("total_" + coin.symbol).innerText = 0;
      document.getElementById("error_quantity_" + coin.symbol).innerText =
        "Your order amount should be less than available amount.";
      document.getElementById("grandTotal").innerText = 0;
    }
  };

  const handleKeyUpPrice = (e, coin) => {
    document.getElementById("error_limit_price_" + coin.symbol).innerText = "";
    let maxUSDT = parseFloat(document.getElementById("max_USDT").innerText);
    let currentQuantity = parseFloat(e.target.value);
    let maxQuantity = parseFloat(
      document.getElementById("max_" + coin.symbol).innerText
    );

    let quantity = document.getElementById("quantity_" + coin.symbol).value;
    let total = 0;
    if (e.target.value) {
      total = e.target.value * quantity;
      document.getElementById("total_" + coin.symbol).innerText = isNaN(total)
        ? ""
        : total;
    } else {
      total =
        document.getElementById("limit_price_span_" + coin.symbol) * quantity;
      document.getElementById("total_" + coin.symbol).innerText = isNaN(total)
        ? ""
        : total;
    }

    let totalSymbolPrice = parseFloat(
      document.getElementById("total_" + coin.symbol).innerText
    );

    if (orderType == "BUY" && totalSymbolPrice > maxUSDT) {
      document.getElementById("limit_price_" + coin.symbol).value = 0;
      document.getElementById("total_" + coin.symbol).innerText = 0;
      document.getElementById("error_limit_price_" + coin.symbol).innerText =
        "Your order amount should be less than available amount.";
    }

    let grandTotal = 0;
    for (
      let index = 0;
      index < document.getElementsByClassName("totals").length;
      index++
    ) {
      grandTotal += isNaN(
        document.getElementsByClassName("totals")[index].innerText
      )
        ? 0
        : +document.getElementsByClassName("totals")[index].innerText;
    }
    document.getElementById("grandTotal").innerText = grandTotal.toFixed(4);

    grandTotal = parseFloat(grandTotal);

    if (orderType == "BUY" && grandTotal > maxUSDT) {
      console.log("grandTotal");
      document.getElementById("limit_price_" + coin.symbol).value = 0;
      document.getElementById("total_" + coin.symbol).innerText = 0;
      document.getElementById("error_limit_price_" + coin.symbol).innerText =
        "Your order amount should be less than available amount.";
      document.getElementById("grandTotal").innerText = 0;
      return;
    }
  };

  return (
    <>
      <Base>
        <div className="container-fluid " id="coinsCalculation">
          <div className="row table-responsive">
            <p style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                <strong>Order Type:</strong> {orderType}
              </span>
              <span>
                <strong>Available USDT: </strong>{" "}
                <span id="max_USDT">
                  {Wallet?.data?.assets?.find((wa) => wa.asset == "USDT")?.free}
                </span>
              </span>
            </p>
            <Formik
              enableReinitialize
              initialValues={{
                coins: Coins,
              }}
              onSubmit={(values, { setErrors }) =>
                handleSubmit(values, setErrors)
              }
            >
              {({ values, errors, setValues }) => (
                <Form>
                  <FieldArray
                    name="coins"
                    render={(arrayHelpers) => {
                      let coins = values.coins;

                      return (
                        <div>
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Number</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Total</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              {IsLoading ? (
                                <tr>
                                  <td colSpan="5">
                                    <div className="loader-container">
                                      <div className="spinner"></div>
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {!IsLoading &&
                                coins?.map((coin, index) => (
                                  <tr key={index}>
                                    <th scope="row">
                                      {coin.symbol}
                                      <Field
                                        className="symbol"
                                        type="hidden"
                                        name={`coins[${index}].symbol`}
                                        value={coin.symbol}
                                      />
                                      <br />
                                      <span
                                        id={"max_" + coin.symbol}
                                        style={{
                                          fontSize: "small",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {
                                          Wallet?.data?.assets?.find(
                                            (wa) => wa.symbol == coin.symbol
                                          )?.free
                                        }
                                      </span>
                                      {Object.keys(errors).length ? (
                                        <div style={{ color: "red" }}>
                                          {
                                            errors?.errors?.find(
                                              (error) =>
                                                error.symbol == coin.symbol
                                            ).message
                                          }
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </th>
                                    <td>
                                      <span>
                                        {coin.symbol.split("USDT")[0]}
                                      </span>
                                      <Field
                                        className="symbolQuantity"
                                        type="number"
                                        onKeyUp={(e) =>
                                          handleKeyUpQuantity(e, coin)
                                        }
                                        id={"quantity_" + coin.symbol}
                                        name={`coins[${index}].quantity`}
                                        placeholder="10"
                                      />
                                      <span
                                        id={"error_quantity_" + coin.symbol}
                                        style={{
                                          color: "red",
                                          maxWidth: "184px",
                                        }}
                                      ></span>
                                    </td>
                                    <td>
                                      <span>Limit Price</span>
                                      <span
                                        className="symbolPrice d-none"
                                        id={"limit_price_span_" + coin.symbol}
                                      >
                                        {coin.limit_price}
                                      </span>
                                      <Field
                                        className="symbolFieldPrice"
                                        type="number"
                                        onKeyUp={(e) =>
                                          handleKeyUpPrice(e, coin)
                                        }
                                        id={"limit_price_" + coin.symbol}
                                        name={`coins[${index}].limit_price`}
                                        placeholder="$20"
                                      />
                                      <span
                                        id={"error_limit_price_" + coin.symbol}
                                        style={{
                                          color: "red",
                                          maxWidth: "184px",
                                        }}
                                      ></span>
                                    </td>
                                    <td>
                                      <p
                                        type="text"
                                        id={"total_" + coin.symbol}
                                        className="totals"
                                      >
                                        {isNaN(coin.quantity * coin.limit_price)
                                          ? ""
                                          : (coin.quantity * coin.limit_price).toFixed(4)}
                                      </p>
                                    </td>

                                    <td>
                                      {values.coins.length > 1 ? (
                                        <button
                                          type="button"
                                          className="btn btnAlert"
                                          onClick={() => {
                                            setValues({
                                              coins: values.coins.filter(
                                                (cs) =>
                                                  cs.symbol !== coin.symbol
                                              ),
                                            });
                                            handleDelete(values.coins, coin);
                                          }}
                                        >
                                          Delete
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                ))}

                              <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col">
                                  Grand Total <br />
                                  <p id="grandTotal">--</p>
                                </th>
                                <th scope="col"></th>
                              </tr>
                            </tbody>
                          </table>
                          <div className="linkingButtons">
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              <button type="submit" className="btn">
                                {IsLoading ? <LoadingButton /> : "Submit"}
                              </button>
                            </a>
                          </div>
                        </div>
                      );
                    }}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Base>
    </>
  );
}
