import React from "react";
import "./index.scss";
import { useSelector, useDispatch } from 'react-redux';
import AdminLoginSlice, { postAdminLogout } from "../../../slices/admin/LoginSlice";


export default function()
{

  const dispatch = useDispatch();
  // const user = useSelector(state => state.login);

    return (
			<div id="NavbarComponent">
				<nav
					className="navbar navbar-expand-lg"
					id="navigation"
					
				>
					<div className="container-fluid">
						<a className="navbar-brand" href="#">
							Multitrade Admin
						</a>

						{/* <button
							className="navbar-toggler"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#navbarSupportedContent"
							aria-controls="navbarSupportedContent"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-icon"></span>
						</button> */}

						<div
							className="collapse navbar-collapse justify-content-end"
							id="navbarSupportedContent"
						>
							<a className="navbar-brand" href="#">
								<i
									className="fa fa-sign-out"
									title="Logout"
									onClick={() => dispatch(postAdminLogout())}
								></i>
							</a>
						</div>
					</div>
				</nav>
			</div>
		);
}