import React from 'react';
import Modal from "react-modal";
import { useSelector, useDispatch } from 'react-redux';
import { getCoins } from '../slices/CoinsSlice';
import { useNavigate } from 'react-router-dom';

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		zIndex: "2000",
		width: "100%",
		maxWidth: "930px",
	},
};

Modal.setAppElement("#yourAppElement");

export default (props) => {
  const history = useNavigate();
  const { coins, isLoading } = useSelector((state) => state.coinsSlice);
  const [OrderType, setOrderType] = React.useState("SELL");
  const dispatch = useDispatch();
  const [Coins, setCoins] = React.useState([]);
  const [SelectedCoins, setSelectedCoins] = React.useState([]);

  React.useEffect(() => {
    dispatch(getCoins()).then(response => setCoins(response.payload.data));
  }, []);

  let subtitle;
	const [modalIsOpen, setIsOpen] = React.useState(false);

  const isOpen = () => {
    //
  }

  React.useEffect(() => {
    if (props?.modalState){
      openModal();
    }
  }, [props?.modalState]);

  React.useEffect(() => {
		props.closed()
	}, [modalIsOpen]);
  
  function openModal() {
		setIsOpen(true);
	}
  function afterOpenModal() {
		// references are now sync'd and can be accessed.
		// subtitle.style.color = "#f00";
	}
  function closeModal() {
		setIsOpen(false);
	}
  
  const handleChecked = (e) => {

    if (e.target.checked){
      document.getElementById(e.target.value).checked = true;
			if (!SelectedCoins?.includes(e.target.value)) {
        let selectedCoins = SelectedCoins;
				selectedCoins.push(e.target.value);
				setSelectedCoins(selectedCoins);
			}
    } else {
      document.getElementById(e.target.value).checked = false;
      if (SelectedCoins?.includes(e.target.value)) {
				let remainingCoins = SelectedCoins;
				let filteredCoins = remainingCoins.filter((rCoin) => rCoin != e.target.value);
				setSelectedCoins(filteredCoins);
			}
    }
	};

  const handleSearch = (e) => {
    setCoins(coins.data);
    // const filterCoins = coins?.data?.filter((coin) => {
		// 	if (coin.indexOf(e.target.value.toUpperCase()) !== -1) {
		// 		return coin;
		// 	}
		// });
    // setCoins(filterCoins);
    Coins.map(coin => {
      if(coin.indexOf(e.target.value.toUpperCase()) !== -1){
        document.getElementById('div'+coin).style.display = 'block';
      } else {
        document.getElementById("div" + coin).style.display = "none";
      }
    })
  }

  // React.useEffect(() => {
  //   if (SelectedCoins.length){
  //     setCoins(SelectedCoins);
  //   } else {
  //     setCoins(coins.data);
  //   }
  // }, [SelectedCoins]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = new URLSearchParams({
			orderType: OrderType,
			coins: SelectedCoins,
		});
    history("/calculation?" + params.toString());
    closeModal();
  }

  return (
		<Modal
			isOpen={modalIsOpen}
			onAfterOpen={afterOpenModal}
			onRequestClose={closeModal}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<div
				className="modal1"
				id="modal"
				tabIndex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<form action="/calculation" method="get" onSubmit={handleSubmit}>
						<div className="modal-content">
							<div className="modal-header">
								<div className="headerButtons">
									<nav>
										<div className="nav nav-tabs" id="nav-tab" role="tablist">
											<input
												type="hidden"
												name="order_type"
												value={OrderType}
											/>
											<button
												className={
													OrderType == "SELL" ? "nav-link active" : "nav-link"
												}
												id="nav-home-tab"
												data-bs-toggle="tab"
												data-bs-target="#nav-home"
												type="button"
												role="tab"
												aria-controls="nav-home"
												aria-selected="true"
												onClick={() => setOrderType("SELL")}
											>
												Sell
											</button>
											<button
												className={
													OrderType == "BUY" ? "nav-link active" : "nav-link"
												}
												id="nav-profile-tab"
												data-bs-toggle="tab"
												data-bs-target="#nav-profile"
												type="button"
												role="tab"
												aria-controls="nav-profile"
												aria-selected="false"
												onClick={() => setOrderType("BUY")}
											>
												Buy
											</button>
										</div>
									</nav>
								</div>
								<div className="searchbar">
									<div>
										<input
											type="text"
											placeholder="Search.."
											name="search"
											onChange={handleSearch}
											style={{ width: "241.19px" }}
										/>
									</div>
								</div>

								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={() => closeModal()}
								></button>
							</div>

							<hr />

							<div className="modal-body" style={{ maxHeight: "275px" }}>
								<div className="container-fluid" id="coinsList">
									<div onSubmit={(e) => e.preventDefault()}>
                  {isLoading ? (
                    <div className="loader-container">
                      <div className="spinner"></div>
                    </div>
										) : (
                      <div className="row">
												{Coins?.map((coin, index) => (
													<div
														className="col-md-4"
														key={index}
														id={"div" + coin}
													>
														<div className="coins">
															<button
																type="button"
																className={
																	index % 2 == 0
																		? "btn modalButton modalBtn"
																		: "btn modalButton"
																}
																// onClick={() =>
																// 	(document.getElementById(coin).checked = true)
																// }
															>
																<input
																	id={coin}
																	type="checkbox"
																	name="coins[]"
																	value={coin}
																	onChange={handleChecked}
																	defaultChecked={
																		SelectedCoins?.includes(coin) ? true : false
																	}
																/>
																<span>{coin}</span>
															</button>
														</div>
													</div>
												))}
											</div>
										)}
									</div>
								</div>
							</div>
							<hr />
							<div className="modal-footer">
								<a href="#">
									{" "}
									<button type="submit" className="btn">
										Submit
									</button>
								</a>
							</div>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
}