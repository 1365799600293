import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../utils/Axios";

const initialState = {
	coins: {},
	isLoading: false,
	errors: {},
};

export const getCoins = createAsyncThunk("coins", async (payload, thunk) => {
	try {
		const coins = await Axios.get("/coins/all");
		return coins.data;
	} catch (error) {
		return thunk.rejectWithValue("Something went wrong with coins.");
	}
});

const CoinsSlice = createSlice({
	name: "CoinsSlice",
	initialState,
	reducers: {
		emptyCoins: (state) => {
			state.coins = {};
		},
	},
	extraReducers: {
		[getCoins.pending]: (state) => {
			state.isLoading = true;
		},
		[getCoins.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.coins = payload;
		},
		[getCoins.rejected]: (state) => {
			state.isLoading = false;
		},
	},
});

export default CoinsSlice.reducer;
export const { emptyCoins } = CoinsSlice.actions;
