import moment from "moment";
import React, { useState, useEffect } from "react";
import "./detailHistory.scss";
import { useSelector, useDispatch } from "react-redux";
import { getOrderDetails } from "../../slices/OrderDetailsSlice";
import { toast, ToastContainer } from "react-toastify";
import Base from "../Base";
import { useParams } from "react-router-dom";
import Axios from "../../utils/Axios";

export default () => {
  const { id } = useParams();
  //console.log(id);

  let [isLoading, setIsloaded] = useState(true);
  //const orderDetails = useSelector((state) => state.OrderDetailsSlice);
  const [OrderDetails, setOrderDetails] = useState([]);
  const dispatch = useDispatch();
  const [fillsLength, setFillsLength] = useState(2);

  useEffect(() => {
    dispatch(getOrderDetails(id)).then((res) => setOrderDetails(res.payload));
  }, [id]);

  const handleCancel = async (e, orderId) => {
    await Axios.post("/cancelAnOrder", { orderId })
      .then((response) => {
        if (response?.data?.data?.status) {
          document.getElementById(orderId).innerText =
            response?.data?.data?.status;
        }
        toast.success(response.data.message);
      })
      .catch((error) => toast.error(error.response.data.message));
  };
  
  useEffect(() => {
    fetchOrderDetails(id);
  }, [id]);

  const fetchOrderDetails = async (orderId) => {
    setIsloaded(true);
    try {
      const response = await dispatch(getOrderDetails(orderId));
      setOrderDetails(response.payload);
      setIsloaded(false);
    } catch (error) {
      setIsloaded(false);
      toast.error(error.response.data.message);
    }
  };
  const resetComponent = () => {
    // Reset state to initial values
    setIsloaded(true);
    setOrderDetails([]);
    setFillsLength(2);
    fetchOrderDetails(id); // Fetch order details again after reset
  };

  return (
    <Base>
      <div onLoad={()=>{
        setIsloaded(false)
      }} className="container-fluid" id="detailHistory">
        <div className="det-history">
          <div
            style={{
              fontSize: "25px",
              marginLeft: "auto",
              width: "max-content",
              marginBottom: "10px",
              marginRight: "8px",
              cursor: "pointer",
            }}
            className="refresh-btn"
          >
            <i
              className="fa fa-refresh"
              aria-hidden="true"
              onClick={resetComponent}
            ></i>
          </div>
          <div className="row">
          {isLoading ? (
                <div style={{width: '100%'}}>
                  <div colSpan="6">
                    <div className="loader-container">
                      <div className="spinner"></div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            {
            OrderDetails?.map((orderDetail) => (
              <div className="col-md-6">
                <div className="historyTable">
                  <table className="table">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          colSpan="2"
                          style={{ position: "relative" }}
                        >
                          <img
                            src={`/asset/icon/${orderDetail.symbol
                              .replace("USDT", "")
                              .toLowerCase()}.png`}
                          />{" "}
                          {orderDetail.symbol}
                          {" - "}{" "}
                          {orderDetail.side == "BUY" ? "BOUGHT" : "SOLD"}
                          {orderDetail.status == "ABORTED" &&
                          orderDetail?.error ? (
                            Object.keys(orderDetail?.error).length ? (
                              <span
                                style={{
                                  position: "absolute",
                                  right: "9px",
                                  top: "30%",
                                  color: "black",
                                  fontSize: "20px",
                                }}
                                className="fa fa-circle-info"
                                title={orderDetail?.error?.msg}
                              ></span>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Limit/Sell</th>
                        <td>{orderDetail.type}</td>
                      </tr>
                      <tr>
                        <th scope="row">Status</th>
                        <td
                          id={orderDetail._id}
                          className="d-flex justify-content-between"
                        >
                          {orderDetail.status}
                          {orderDetail.status == "NEW" ||
                          orderDetail.status == "PARTIALLY_FILLED" ? (
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={(e) => handleCancel(e, orderDetail._id)}
                            >
                              Cancel Now
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Requested Quantity</th>
                        <td>{orderDetail.original_quantity}</td>
                      </tr>
                      <tr>
                        <th scope="row">Executed Quantity</th>
                        <td>{orderDetail.executed_quantity}</td>
                      </tr>
                      <tr
                        style={{
                          display: `${
                            orderDetail.fills.length < 1 ? "none" : "table-row"
                          }`,
                        }}
                      >
                        <th style={{ border: "none" }} scope="row" colSpan="1">
                          Fills
                        </th>
                      </tr>
                      {orderDetail.fills.length < 1 ? (
                        <tr>
                          <th scope="row">Price</th>
                          <td>
                            {(
                              orderDetail.executed_price /
                              orderDetail.executed_quantity
                            ).toFixed(2)}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td
                            style={{
                              paddingRight: "0",
                            }}
                          >
                            <table
                              style={{
                                width: "100%",
                                border: "1px solid",
                                borderColor: "inherit",
                                borderRightWidth: "0",
                              }}
                            >
                              <tbody style={{ border: "none" }}>
                                <tr style={{ padding: "4px" }} scope="row">
                                  <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                  <th>Price</th>
                                </tr>
                                {orderDetail.fills
                                  .slice(0, fillsLength)
                                  .map((fill, index) => (
                                    <React.Fragment key={index}>
                                      <tr style={{ padding: "4px" }}>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                        <td>{fill.price}</td>
                                      </tr>
                                    </React.Fragment>
                                  ))}
                                <tr>
                                  <th
                                    style={{
                                      height: "35px",
                                      display: `${orderDetail.fills.length <= 2?'none':"table-cell"}`
                                    }}
                                  >
                                    &nbsp;
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td
                            style={{
                              paddingLeft: "0",
                            }}
                          >
                            <table
                              style={{
                                width: "100%",
                                border: "1px solid",
                                borderColor: "inherit",
                                borderLeftWidth: "0",
                              }}
                            >
                              <tbody style={{ border: "none" }}>
                                <tr style={{ padding: "4px" }} scope="row">
                                  <th>Quantity</th>
                                </tr>
                                {orderDetail.fills
                                  .slice(0, fillsLength)
                                  .map((fill, index) => (
                                    <React.Fragment key={index}>
                                      <tr style={{ padding: "4px" }}>
                                        <td>{fill.qty}</td>
                                      </tr>
                                    </React.Fragment>
                                  ))}
                                {orderDetail.fills.length > 2 && (
                                  <tr>
                                    <td colSpan="2">
                                      <div
                                        style={{
                                          fontSize: "20px",
                                          textAlign: "left",
                                          transform: "translateX(-40%)",
                                          paddingRight: "15px",
                                          marginBottom: "5px",
                                        }}
                                        onClick={() => {
                                          setFillsLength(
                                            orderDetail.fills.length ==
                                              fillsLength
                                              ? 2
                                              : orderDetail.fills.length
                                          );
                                        }}
                                      >
                                        {orderDetail.fills.length ==
                                        fillsLength ? (
                                          <i
                                            style={{
                                              border: "1px solid gray",
                                              width: "25px",
                                              height: "25px",
                                              textAlign: "center",
                                              borderRadius: "30px",
                                              paddingTop: "2px",
                                            }}
                                            className="fa-solid fa-angle-up"
                                          ></i>
                                        ) : (
                                          <i
                                            style={{
                                              border: "1px solid gray",
                                              width: "25px",
                                              height: "25px",
                                              textAlign: "center",
                                              borderRadius: "30px",
                                              paddingTop: "2px",
                                            }}
                                            className="fa-solid fa-angle-down"
                                          ></i>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}

                      <tr style={{ borderTopWidth: "1px" }}>
                        <th scope="row">Total</th>
                        <td>{orderDetail.total}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Base>
  );
};
