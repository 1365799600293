import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import Axios from "../utils/Axios";
import { toast } from 'react-toastify';
import { saveApiKeys } from "./SaveApiKeySlice";

const initialState = {
	login: {},
	isLoading: false,
  errors: {}
};

export const postLogin = createAsyncThunk(
  'login',
  async (payload, thunkAPI) => {
    try {
      const res = await Axios.post("/login", payload);
      return res.data;
    } catch(error){
      return thunkAPI.rejectWithValue('Something went wrong in login');
    }
  }
);

export const postLogout = createAsyncThunk(
  'logout',
  async (payload, thunkAPI) => {
    try {
      const res = await Axios.post("/logout", {});
      return res.data;
    } catch(error) {
      return thunkAPI.rejectWithValue('something went wrong in logout');
    }
  }
)


// const logout = createAction('logout');

const loginSlice = createSlice({
	name: "Login",
	initialState,
	reducers: {
		login: (state, { payload }) => {
			
			state.login = payload;
		},
		logout: (state) => {
			
			state.login = {};
		},
		setApiKey: (state) => {
			state.login.api_keys = true;
		},
	},
	extraReducers: {
		[postLogin.pending]: (state) => {
			state.isLoading = true;
			state.errors = {};
		},
		[postLogin.fulfilled]: (state, action) => {
			state.isLoading = false;
			
			if (action.payload.isAuth == false) {
				state.errors = action.payload;
				toast.error(state.errors.message);
			} else {
				toast.success("Login Success");
				state.login = action.payload;
			}
			// state.login = action.payload;
		},
		[postLogin.rejected]: (state, action) => {
			state.isLoading = false;
		},
		[postLogout.pending]: (state) => {
			state.isLoading = true;
			state.errors = {};
		},
		[postLogout.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.login = {};
      document.cookie = "userAuth=;"
      window.location.href="/";
		},
		[postLogout.rejected]: (state, action) => {
			state.isLoading = false;
		},
		[saveApiKeys.fulfilled]: (state, action) => {

			state.login.api_keys = true;
			state.login.api_keys_status = "VALID";
		},
	},
});

export const { login, logout, setApiKey } = loginSlice.actions;
// export const loginAction = loginSlice.actions;

export default loginSlice.reducer;