import Axios from "../utils/Axios";
import React from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "2000",
    width: "100%",
    maxWidth: "750px",
  },
};

Modal.setAppElement("#yourAppElement");

export default (props) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    // dispatch(getCoins()).then((response) => setCoins(response.payload.data));
  }, []);

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const isOpen = () => {
    console.log("click");
  };

  React.useEffect(() => {
    if (props?.modalState) {
      openModal();
    }
  }, [props?.modalState]);

  React.useEffect(() => {
    props.closed();
    console.log(modalIsOpen);
  }, [modalIsOpen]);

  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }
  function closeModal() {
    setIsOpen(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      old_password: e.target.elements.old_password.value,
      password: e.target.elements.password.value,
    };
    const response = await Axios.post("/change-password", data)
      .then((ress) => {
        toast.success(ress.data.message);
        closeModal();
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div
        className="modal1"
        id="modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <form onSubmit={handleSubmit}>
            <div className="modal-content">
              <div className="modal-header">
                <strong>Change Password</strong>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => closeModal()}
                ></button>
              </div>

              <hr />

              <div className="modal-body" style={{ overflow: "unset" }}>
                <div className="container-fluid" id="coinsList">
                  <div className="row" style={{ marginBottom: "20px" }}>
                    <div
                      className="form-group"
                      style={{ marginBottom: "10px" }}
                    >
                      <label htmlFor="password">
                        <strong>Old Password</strong>
                      </label>
                      <input
                        type="text"
                        id="old_password"
                        name="old_password"
                        minLength="8"
                        className="form-control"
                        placeholder="Enter Old Password"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">
                        <strong>New Password</strong>
                      </label>
                      <input
                        type="text"
                        id="password"
                        name="password"
                        minLength="8"
                        className="form-control"
                        placeholder="Enter New Password"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <a href="#">
                  {" "}
                  <button type="submit" className="btn">
                    Submit
                  </button>
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
