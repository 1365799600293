import React from "react";
import "./navbar.scss";
import { useSelector, useDispatch } from "react-redux";
import LoginSlice, { postLogout } from "../../slices/LoginSlice";

export default function () {
  const dispatch = useDispatch();
  // const user = useSelector(state => state.login);

  return (
    <div id="NavbarComponent">
      <nav className="navbar navbar-expand-lg" id="navigation">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            Multitrade
          </a>
          <label htmlFor="check">
            <i className="fas fa-bars" id="btn"></i>
            <i className="fas fa-times" id="cancel"></i>
          </label>

          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}

          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <a className="navbar-brand" href="#">
              <i
                className="fa fa-sign-out"
                title="Logout"
                onClick={() => dispatch(postLogout())}
              ></i>
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}
