import moment from "moment";
import React, { useState, useEffect } from "react";
import "./details.scss";
import { useSelector, useDispatch } from "react-redux";
import { getOrderDetails } from "../../../../slices/admin/OrderDetailsSlice";
import Base from "../../Base";
import {useParams} from 'react-router-dom';

export default () => {

  const {id, orderId} = useParams();
  console.log(id);
  
	const orderDetails = useSelector((state) => state.OrderDetailsSlice);
	const [OrderDetails, setOrderDetails] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getOrderDetails({ id, orderId })).then((res) =>
			setOrderDetails(res.payload)
		);
	}, []);

	return (
		<Base>
			<div className="container-fluid" id="detailHistory">
				<div className="row">
					{OrderDetails?.map((orderDetail) => (
						<div className="col-md-6">
							<div className="historyTable">
								<table className="table">
									<thead>
										<tr>
											<th
												scope="col"
												colSpan="2"
												style={{ position: "relative" }}
											>
												<img src="/asset/images/bitcoinLogo.png" />{" "}
												{orderDetail.symbol}
												{" - "} {orderDetail.side == "BUY" ? "BOUGHT" : "SOLD"}
												{orderDetail?.error &&
													Object.keys(orderDetail?.error).length && (
														<span
															style={{
																position: "absolute",
																right: "9px",
																top: "30%",
																color: "black",
																fontSize: "20px",
															}}
															className="fa fa-circle-info"
															title={orderDetail?.error?.msg}
														></span>
													)}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th scope="row">Limit/Sell</th>
											<td>{orderDetail.type}</td>
										</tr>
										<tr>
											<th scope="row">Status</th>
											<td>{orderDetail.status}</td>
										</tr>
										<tr>
											<th scope="row">Requested Quantity</th>
											<td>{orderDetail.original_quantity}</td>
										</tr>
										<tr>
											<th scope="row">Quantity</th>
											<td>{orderDetail.executed_quantity}</td>
										</tr>
										<tr>
											<th scope="row">Total</th>
											<td>{orderDetail.total}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					))}
				</div>
			</div>
		</Base>
	);
};
