import moment from "moment";
import React, { useState, useEffect } from "react";
import './index.scss'
import { useSelector, useDispatch } from "react-redux";
import { getOrders } from '../../../../slices/admin/OrdersSlice';
import { ToastContainer } from "react-toastify";
import Base from "../../Base";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

export default () => {
  const { id } = useParams();
  const orders = useSelector((state) => state.OrdersSlice);
	const [Orders, setOrders] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getOrders(id)).then((res) => setOrders(res.payload));
	}, []);

    return (
			<Base>
				<div className="container-fluid" id="history">
					<div className="table-responsive">
						<ToastContainer />
						<table className="table">
							<thead>
								<tr>
									<th scope="col">ID</th>
									<th scope="col">Buy/Sell</th>
									<th scope="col">TIME</th>
									<th scope="col">DATE</th>
									<th scope="col">STATUS</th>
									<th scope="col">ACTION</th>
								</tr>
							</thead>
							<tbody>
								{Orders?.map((order, key) => (
									<tr key={key}>
										<th scope="row" className="id">
											{key + 1}
										</th>
										<td className={order.order_type == "BUY" ? "buy" : "sell"}>
											<span>
												{order.order_type == "BUY" ? "Bought" : "Sold"}
											</span>
										</td>
										<td className="time">
											{moment(order.createdAt).format("HH:mm")}
										</td>
										<td className="date">
											{moment(order.createdAt).format("D/M/Y")}
										</td>
										<td className="status">{order.status}</td>
										<td className="action">
											<Link to={"/admin/users/"+id+"/orders/" + order._id}>
												<button type="button" className="btn">
													<i className="fa-solid fa-eye"></i>
												</button>
											</Link>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</Base>
		);
}