import moment from "moment";
import React, { useState, useEffect } from "react";
import './index.scss'
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from '../../../slices/admin/UsersSlice';
import { ToastContainer } from "react-toastify";
import Base from "../Base";
import { Link } from "react-router-dom";
import CreateUserModal from "./CreateUserModal";

export default () => {

  const users = useSelector((state) => state.UsersSlice);
	const [Users, setUsers] = useState([]);
	const dispatch = useDispatch();

  const [OpenModal, setOpenModal] = React.useState(false);

	const isOpen = () => {
		setOpenModal(true);
	};

	const closed = () => {
		setOpenModal(false);
	};

	useEffect(() => {
		dispatch(getUsers()).then((res) => setUsers(res.payload));
	}, []);

  const updateUsers = () => {
    dispatch(getUsers()).then((res) => setUsers(res.payload));
  }

    return (
			<Base>
				<div className="container-fluid" id="adminUsers">
					<CreateUserModal
						modalState={OpenModal}
						isOpen={isOpen}
						closed={closed}
						updateUsers={updateUsers}
					/>
					<div className="row create-button-div">
						<button
							className="btn create-button"
							onClick={() => setOpenModal(true)}
						>
							Create
						</button>
					</div>
					<div className="table-responsive">
						<ToastContainer />
						<table className="table">
							<thead>
								<tr>
									<th scope="col">S.No.</th>
									<th scope="col">Name</th>
									<th scope="col">Email</th>
									<th scope="col">Testing Mode</th>
									<th scope="col">Api Keys</th>
									<th scope="col">Joinned At</th>
									<th scope="col">ACTION</th>
								</tr>
							</thead>
							<tbody>
								{Users?.map((user, key) => (
									<tr>
										<th scope="row" className="id">
											{key + 1}
										</th>
										<td>{user.name}</td>
										<td className="time">{user.email}</td>
										<td className="status">
											{user.testing_mode ? (
												<i
													className="fa fa-check"
													style={{ color: "green" }}
												></i>
											) : (
												<i className="fa fa-times" style={{ color: "red" }}></i>
											)}
										</td>
										<td className="status">
											{user.api_keys ? (
												<i
													className="fa fa-check"
													style={{ color: "green" }}
												></i>
											) : (
												<i className="fa fa-times" style={{ color: "red" }}></i>
											)}
										</td>
										<td className="date">
											{moment(user.createdAt).format("D/M/Y")}{" "}
											{moment(user.createdAt).format("HH:mm")}
										</td>
										<td className="action">
											<Link to={"/admin/users/" + user._id}>
												<button type="button" className="btn">
													<i className="fa-solid fa-eye"></i>
												</button>
											</Link>
											<Link to={"/admin/users/" + user._id + "/orders"}>
												<button type="button" className="btn">
													<i className="fa-solid fa-shop"></i>
												</button>
											</Link>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</Base>
		);
}