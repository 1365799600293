import React, { useEffect, useState,useRef } from "react";
import "./dashboard.scss";
import Base from "../Base";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { getDashboardData } from "../../slices/DashboardSlice";
import { Card } from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Chart } from 'chart.js/auto';
import moment from 'moment';


const Dashboard = () => {
  const [Orders, setOrders] = useState([]);
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const initialDate = dayjs(new Date()).startOf('day').format();
  const nowDate = dayjs(new Date()).format();
  const [dates, setDates] = useState({
    startDate: initialDate,
    endDate: nowDate,
  });

  
  ////hooks for chart
  const chartRef = useRef();
  const chartInstance = useRef(null);


  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(getDashboardData(dates)).then((res) => setOrders(res.payload));
  }, [rerenderFlag, dispatch, dates]);

  let buy = 0;
  let sell = 0;

  Orders?.data?.forEach((order) => {
    if (order?.order_type === "BUY") {
      buy = buy + 1;
    } else {
      sell = sell + 1;
    }
  });

  let traded_Amount = 0;

  Orders?.data?.forEach((order) => {
    traded_Amount += order?.executed_amount ? order.executed_amount : 0;
  });

  function setDate(event) {
    setDates({
      startDate: dayjs(event[0].$d).startOf('day').format(),
      endDate: dayjs(event[1].$d).endOf('day').format()
    });
  }
  console.log(dates.startDate, dates.endDate);


  ///logic for chart
  const profitLoss = {
    date: [],
    profit: [],
    color: []
  }
   const data = {
    labels: profitLoss.date,
    datasets: [
      {
        label: 'Profit Loss',
        data: profitLoss.profit,
        borderWidth: 4,
        backgroundColor: profitLoss.color, // Customize colors as needed
      },
    ],
  };
  
  useEffect(() => {
      Orders?.data?.forEach( (order)=>{


        let profit = order?.order_type == "BUY"
        ? isNaN(order?.now_amount - order?.executed_amount)
          ? ""
          : (
              order?.now_amount - order?.executed_amount
            ).toFixed(4)
        : isNaN(order?.executed_amount - order?.now_amount)
        ? ""
        : (
            order?.executed_amount - order?.now_amount
          ).toFixed(4)
        
        //let profit = `${order.executed_amount!= undefined?order.executed_amount:0}`- order.now_amount;
        profitLoss.profit.unshift(profit)
        profitLoss.date.unshift(moment(order?.createdAt).format("D/M/Y"))
        profitLoss.color.unshift(profit<0? 'red': 'green')
      })
    }, [profitLoss]);
    
  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    // Destroy the previous chart instance if it exists
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // Create a new chart instance
    chartInstance.current = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Graph Per Order'
          }
        }
      },
    });
     // Cleanup: destroy the chart when the component is unmounted
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);
  


  return (
    <>
      <Base>
        <div className="dashboard-container">
         <div style={{ padding: "20px" }}>
          <div className="filter" style={{display: 'flex', alignItems: 'center', width: '100%', marginBottom: '20px'}}>
            <div style={{width: '100%'}}>
         <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer style={{width: '100%'}} components={["DateRangePicker"]}>
              <div component="DateRangePicker" >
                <DemoItem
                  component="DateRangePicker"
                >
                  <DateRangePicker
                    defaultValue={[dayjs(new Date()), dayjs(new Date())]}
                    onChange={setDate}
                  />
                </DemoItem>
              </div>
            </DemoContainer>
          </LocalizationProvider>
          </div>
            <div
              style={{
                fontSize: "25px",
                width: "max-content",
                marginBottom: "10px",
                marginRight: "10px",
                marginLeft: "10px"
              }}
              className="refresh-btn"
            >
              <i
                class="fa fa-refresh"
                aria-hidden="true"
                onClick={() => {
                  setRerenderFlag(!rerenderFlag);
                }}
              ></i>
            </div>
            </div>
            <div
              className="row"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="col-xs-12 " style={{ width: "100%" }}>
                <div className="row" style={{ display: "flex" }}>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <Card
                      style={{
                        height: "100%",
                        border: "none",
                        backgroundColor: "#f3f0f8",
                      }}
                      className="card total-card"
                    >
                      <div style={{ padding: "10px" }}>
                        <Typography
                          paddingLeft={2}
                          fontWeight={500}
                          variant="h5"
                          component="div"
                        >
                          Total Orders
                        </Typography>
                        <Typography
                          paddingLeft={3}
                          fontWeight={600}
                          variant="h2"
                        >
                          {Orders.data?.length ?? 0}
                        </Typography>
                      </div>
                    </Card>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <Card
                      style={{ height: "100%", border: "none" }}
                      className="card trade-amount"
                    >
                      <div style={{ padding: "10px" }}>
                        <Typography
                          paddingLeft={1}
                          fontWeight={500}
                          variant="h5"
                          component="div"
                        >
                          Traded Amount
                        </Typography>
                        <Typography
                          paddingLeft={1}
                          fontWeight={600}
                          variant="h5"
                        >
                          {traded_Amount}
                        </Typography>
                      </div>
                    </Card>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    <div
                      className="row"
                      style={{ display: "flex", gap: "20px" }}
                    >
                      <div className="col-sm-6 col-xs-12 col-md-6 col-lg-12">
                        <Card
                          style={{ border: "none", backgroundColor: "#f3f0f8" }}
                          className="card sell-buy"
                        >
                          <div style={{ padding: "10px" }}>
                            <Typography
                              fontWeight={500}
                              variant="h9"
                              component="div"
                            >
                              Total Buy Orders
                            </Typography>
                            <Typography
                              paddingLeft={3}
                              fontWeight={600}
                              variant="h4"
                            >
                              {buy}
                            </Typography>
                          </div>
                        </Card>
                      </div>
                      <div className="col-sm-6 col-xs-12 col-md-6 col-lg-12">
                        <Card
                          style={{ border: "none" }}
                          className="card sell-buy"
                        >
                          <div style={{ padding: "10px" }}>
                            <Typography
                              fontWeight={500}
                              variant="h9"
                              component="div"
                            >
                              Total Sell Orders
                            </Typography>
                            <Typography
                              paddingLeft={3}
                              fontWeight={600}
                              pad
                              variant="h4"
                            >
                              {sell}
                            </Typography>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12" style={{ width: "100%" }}>
                <div
                  className="row"
                  style={{ paddingTop: "20px", display: "flex" }}
                >
                  <div className="col-xs-12 col-md-8">
                    <Card style={{ border: "none", padding: "10px" }}>
                    <canvas ref={chartRef} />
                    </Card>
                  </div>
                  <div class="col-xs-12 col-md-4">
                    <div style={{ height: "100%" }} class="card">
                      Content for PopularCard
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Base>
    </>
  );
};

export default Dashboard;
