import moment from "moment";
import React, { useState, useEffect } from "react";
import "./detail.scss";
import { useSelector, useDispatch } from "react-redux";
import { getUserDetail } from "../../../slices/admin/UserDetailSlice";
import { ToastContainer } from "react-toastify";
import Base from "../Base";
import {useParams} from 'react-router-dom';

export default () => {

  const {id} = useParams();
  console.log(id);
  
	const UserDetailFromSlice = useSelector((state) => state.UserDetailSlice);
	const [UserDetail, setUserDetail] = useState(UserDetailFromSlice);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getUserDetail(id)).then((res) => setUserDetail(res.payload));
	}, []);

	return (
		<Base>
			<div className="container-fluid" id="detailHistory">
				<div className="row">
					{console.log(UserDetail)}
					{UserDetail ? (
						<div className="col-md-6">
							<div className="historyTable">
								<table className="table">
									<tbody>
										<tr>
											<th scope="row">First Name</th>
											<td>{UserDetail?.first_name}</td>
										</tr>
										<tr>
											<th scope="row">Last Name</th>
											<td>{UserDetail?.last_name}</td>
										</tr>
										<tr>
											<th scope="row">Email</th>
											<td>{UserDetail?.email}</td>
										</tr>
										<tr>
											<th scope="row">Testing Mode</th>
											<td>{UserDetail?.testing_mode ? "Yes" : "No"}</td>
										</tr>
										<tr>
											<th scope="row">Api Keys</th>
											<td>{UserDetail?.api_keys ? "Yes" : "No"}</td>
										</tr>
										<tr>
											<th scope="row">Created At</th>
											<td>
												{moment(UserDetail?.createdAt).format("D/M/Y")}{" "}
												{moment(UserDetail.createdAt).format("HH:mm")}
											</td>
										</tr>
										<tr>
											<th scope="row">Updated At</th>
											<td>
												{moment(UserDetail?.updatedAt).format("D/M/Y")}{" "}
												{moment(UserDetail.updatedAt).format("HH:mm")}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					) : (
						""
					)}
				</div>
			</div>
		</Base>
	);
};
