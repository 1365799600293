import React from 'react'
import './index.scss'
import Base from '../Base';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { toast } from 'react-toastify';

export default function () {

  // let {orderType, coins} = useParams();
  const navigate = useNavigate();

  const queryString = window.location.search;
	
  const urlParams = new URLSearchParams(queryString);

    return (
			<>
				<Base>
					<div
						className="container-fluid table-responsive"
						id="history"
					>Dashboard</div>
				</Base>
			</>
		);
}