import React, { useEffect } from "react";
import { postLogin } from "../../slices/LoginSlice";
import "./login.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import Axios from "../../utils/Axios";

export default function () {

  const [IsLoading, setIsLoading] = React.useState(false);
  
	const dispatch = useDispatch();
	const location = useLocation();

	// useEffect(() => {
	//   toast('hello! i am working');
	// }, [])

	const handleSubmit = (event) => {
		event.preventDefault();
    setIsLoading(true);

    let email = event.target.elements.email.value;
    Axios.post("/forgot-password", { email })
			.then((response) => {
        toast.success(response.data.message);
        setIsLoading(false);
      })
			.catch((error) => {
        setIsLoading(false);
        toast.success(error.response.data.message);
      });
	};

	const LoadingButton = () => {
		return <span className="spinner-grow spinner-grow-sm"></span>;
	};

	return (
		<div className="container-fluid" id="loginForm">
			<ToastContainer />
			<form onSubmit={handleSubmit}>
				<div className="formHeading">
					<h2 className="heading">Forgot Password</h2>
				</div>

				<div className="mb-3 field">
					<label htmlFor="exampleInputEmail1" className="form-label">
						Email address
					</label>
					<input
						type="email"
						className="form-control"
						id="exampleInputEmail1"
						aria-describedby="emailHelp"
						name="email"
						required
					/>
      </div>
				<div className="loginButton d-grid">
					<button
						type="submit"
						className="btn"
						disabled={IsLoading ? true : false}
					>
						{IsLoading ? <LoadingButton /> : "Submit"}
					</button>
				</div>
			</form>
		</div>
	);
}
