// import React, { useState, useEffect } from "react";
// import moment from "moment";
// import { useSelector, useDispatch } from "react-redux";
// import { getOrders } from "../../slices/OrdersSlice";
// import { toast, ToastContainer } from "react-toastify";
// import Base from "../Base";
// import { Link } from "react-router-dom";
// import DataTable from "react-data-table-component"; // Import the DataTable component

// export default () => {
//   const { orders, isLoading } = useSelector((state) => state.orderSlice);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getOrders(currentPage)).then((res) => {
//       setTotalPages(res.payload.pagination.totalPages);
//     });
//   }, [currentPage, dispatch]);

//   const handleCancel = async (e, orderId) => {
//     // Your cancel logic here
//   };

//   const columns = [
//     {
//       name: "ID",
//       selector: (_, index) => index + 1,
//       sortable: true,
//     },
//     {
//       name: "Buy/Sell",
//       selector: "order_type",
//       sortable: true,
//       cell: (row) => (
//         <span className={row.order_type === "BUY" ? "buy" : "sell"}>
//           {row.order_type === "BUY" ? "B" : "S"}
//         </span>
//       ),
//     },
//     {
//       name: "TIME",
//       selector: "createdAt",
//       sortable: true,
//       cell: (row) => moment(row.createdAt).format("HH:mm"),
//     },
//     // Add other columns similarly
//     // ...
//     {
//       name: "ACTION",
//       sortable: false,
//       cell: (row) => (
//         <div className="action">
//           <Link to={"/history/" + row._id}>
//             <button type="button" className="btn">
//               <i className="fa-solid fa-eye"></i>
//             </button>
//           </Link>
//           {/* Add other action buttons */}
//           {/* ... */}
//           <button
//             type="button"
//             className="btn"
//             onClick={(e) => handleCancel(e, row._id)}
//           >
//             <i className="fa-solid fa-xmark"></i>
//           </button>
//         </div>
//       ),
//     },
//   ];

//   return (
//     <Base>
//       <div className="container-fluid" id="history">
//         <div className="table-responsive">
//           <div
//             style={{
//               fontSize: "25px",
//               marginLeft: "auto",
//               width: "max-content",
//               marginBottom: "10px",
//               cursor: "pointer",
//               marginRight: "8px",
//             }}
//             className="refresh-btn"
//           >
//             {/* Your refresh button here */}
//           </div>
//           <ToastContainer />
//           <DataTable
//             title="Order History"
//             columns={columns}
//             data={orders.data?.orders || []}
//             progressPending={isLoading}
//             pagination
//             paginationServer
//             paginationTotalRows={orders.data?.pagination?.totalItems || 0}
//             paginationPerPage={10}
//             onChangePage={(page) => setCurrentPage(page)}
//           />
//         </div>
//       </div>
//     </Base>
//   );
// };

import moment from "moment";
import React, { useState, useEffect } from "react";
import "./history.scss";
import { useSelector, useDispatch } from "react-redux";
import { getOrders } from "../../slices/OrdersSlice";
import { toast, ToastContainer } from "react-toastify";
import Base from "../Base";
import { Link } from "react-router-dom";
import Axios from "../../utils/Axios";
import { render } from "react-dom";

export default () => {
  const { orders, isLoading } = useSelector((state) => state.orderSlice);
  const [Orders, setOrders] = useState([]);
  const dispatch = useDispatch();
  const [rerenderFlag, setRerenderFlag] = useState(false);

  useEffect(() => {
    dispatch(getOrders()).then((res) => setOrders(res.payload));
  }, [rerenderFlag]);

  const handleCancel = async (e, orderId) => {
    await Axios.post("/cancelOrders", { orderId })
      .then((response) => toast.success(response.data.message))
      .catch((error) => toast.error(error.response.data.message));
    setRerenderFlag((prev) => !prev);
  };
  console.log(Orders.data);
  const spanElements = [];
  
  // Loop three times to create three span elements
  for (let i = 0; i < Orders?.data?.pagination.totalPages; i++) {
    spanElements.push(
  <span 
      className="page-no" 
      onClick={(e) => {
        // Remove active class from all elements with class "page-no"
        const allPageNumbers = document.querySelectorAll('.page-no');
        allPageNumbers.forEach((element) => {
          if (element !== e.target) {
            element.classList.remove('active');
          }
        });

        // Add active class to the clicked element
        e.target.classList.add('active');

        // Dispatch action and update state
        dispatch(getOrders(i + 1)).then((res) => setOrders(res.payload));
      }} 
      key={i}
    >
      {i + 1}
    </span>
    );
  }
  return (
    <Base>
      <div className="container-fluid" id="history">
        <div className="table-responsive">
          <div
            style={{
              fontSize: "25px",
              marginLeft: "auto",
              width: "max-content",
              marginBottom: "10px",
              cursor: "pointer",
              marginRight: "8px",
            }}
            className="refresh-btn"
          >
            <i
              className="fa fa-refresh"
              aria-hidden="true"
              onClick={() => {
                setRerenderFlag(!rerenderFlag);
              }}
            ></i>
          </div>
          <ToastContainer />
          <table className="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Buy/Sell</th>
                <th scope="col">TIME</th>
                <th scope="col">DATE</th>
                <th scope="col">STATUS</th>
                <th scope="col">SP/ CP</th>
                <th scope="col">NOW</th>
                <th scope="col">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="8">
                    <div className="loader-container">
                      <div className="spinner"></div>
                    </div>
                  </td>
                </tr>
              ) : (
                ""
              )}
              {!isLoading &&
                Orders?.data?.orders.map((order, key) => (
                  <tr  key={key}>
                    <th scope="row" className="id">
                      {key + 1}
                    </th>
                    <td className={order?.order_type == "BUY" ? "buy" : "sell"}>
                      <span>{order?.order_type == "BUY" ? "B" : "S"}</span>
                    </td>
                    <td className="time">
                      {moment(order?.createdAt).format("HH:mm")}
                    </td>
                    <td className="date">
                      {moment(order?.createdAt).format("D/M/Y")}
                    </td>
                    <td className="status">{order?.status}</td>
                    <td className="cost">{order?.executed_amount}</td>
                    <td
                      className="CurrentPrice"
                      style={{ position: "relative" }}
                    >
                      {order?.now_amount}
                      <span
                        style={{
                          position: "absolute",
                          right: "10px",
                          color:
                            Number(
                              order?.order_type == "BUY"
                                ? order?.now_amount - order?.executed_amount
                                : order?.executed_amount - order?.now_amount
                            ) > 0
                              ? "green"
                              : "red",
                        }}
                      >
                        &nbsp;{" "}
                        {order?.order_type == "BUY"
                          ? isNaN(order?.now_amount - order?.executed_amount)
                            ? ""
                            : (
                                order?.now_amount - order?.executed_amount
                              ).toFixed(4)
                          : isNaN(order?.executed_amount - order?.now_amount)
                          ? ""
                          : (
                              order?.executed_amount - order?.now_amount
                            ).toFixed(4)}
                      </span>
                    </td>
                    <td className="action">
                      <Link to={"/history/" + order?._id}>
                        <button type="button" className="btn">
                          <i className="fa-solid fa-eye"></i>
                        </button>
                      </Link>

                      <a href="##">
                        <button
                          type="button"
                          className="btn"
                          style={{
                            background:
                              order.order_type == "BUY" ? "red" : "green",
                          }}
                        >
                          <Link
                            style={{ color: "white" }}
                            to={
                              "/calculation?" +
                              new URLSearchParams({
                                orderType:
                                  order.order_type == "BUY" ? "SELL" : "BUY",
                                coins: order.filled_coins?.map((coin) => coin.symbol),
                                quantities: order.filled_coins?.map(
                                  (coin) => coin.quantity
                                ),
                              })
                            }
                          >
                            {order.order_type == "BUY" ? "Sell" : "Buy"}
                          </Link>
                        </button>
                      </a>
                      <a href="#">
                        <button
                          type="button"
                          className="btn"
                          onClick={(e) => handleCancel(e, order._id)}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </button>
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="pagination">
            {
              spanElements
            }
          </div>
      </div>
    </Base>
  );
};
