import React, { useEffect, useState } from "react";
import "./wallet.scss";
import { useSelector, useDispatch } from "react-redux";
import Base from "../Base";
import { getWallet } from "../../slices/WalletSlice";
import Modal from "../Modal";

export default () => {
  const { wallet, isLoading } = useSelector((state) => state.walletSlice);
  const [Wallet, setWallet] = useState([]);
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  const handleSearch = (e) => {
    setSearchQuery(e.target.value); // Update search query state
  };
  const filteredWallet = Wallet?.data?.assets.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWallet({page: 1, searchQuery})).then((res) => setWallet(res.payload));
  }, [rerenderFlag]);

  useEffect(() => {
    dispatch(getWallet({page: 1, searchQuery})).then((res) => setWallet(res.payload));
  }, [searchQuery]);

  const [OpenModal, setOpenModal] = React.useState(false);

  const isOpen = () => {
    setOpenModal(true);
  };

  const closed = () => {
    setOpenModal(false);
  };
  
  const spanElements = [];
  if(!searchQuery){
  
  for (let i = 0; i < wallet?.data?.totalPages; i++) {
    spanElements.push(
  <span 
      className="page-no" 
      onClick={(e) => {
        // Remove active class from all elements with class "page-no"
        const allPageNumbers = document.querySelectorAll('.page-no');
        allPageNumbers.forEach((element) => {
          if (element !== e.target) {
            element.classList.remove('active');
          }
        });

        // Add active class to the clicked element
        e.target.classList.add('active');

        // Dispatch action and update state
        dispatch(getWallet({page: i + 1, searchQuery})).then((res) => setWallet(res.payload));
      }} 
      key={i}
    >
      {i + 1}
    </span>
    );
  }
}
//console.log(Wallet?.data?.assets.find((wa) => wa.asset == "USDT")?.free)

let totalCoinsPrice = 0;
Wallet?.data?.assets.forEach(coin =>{
  // if(coin?.price){
    totalCoinsPrice += parseFloat(coin.amount_in_usdt);
  // }
})

  return (
		<Base>
			<Modal modalState={OpenModal} isOpen={isOpen} closed={closed} />
			<div className="container-fluid" id="mainDetail">
				<div className="row">
					<div
						style={{
							fontSize: "25px",
							marginLeft: "auto",
							width: "max-content",
							marginBottom: "10px",
							marginRight: "8px",
						}}
						className="refresh-btn"
					>
						<i
							className="fa fa-refresh"
							aria-hidden="true"
							onClick={() => {
								setRerenderFlag(!rerenderFlag);
							}}
						></i>
					</div>
					<div className="overallTotal">
						<div className="total">
							<div className="heading">
								<h4>Total</h4>
							</div>
							<div className="overall">
								{/* {Wallet?.data?.assets.find((wa) => wa.asset == "USDT")?.free} */}
								<h6> USDT: {totalCoinsPrice}</h6>
							</div>
						</div>

						<div className="searchbar">
							<form>
								<input
									type="text"
									placeholder="Search.."
									name="search"
									value={searchQuery} // Controlled input value
									onChange={handleSearch} // Call the handleSearch function on input change
								/>
								<button className="btn" type="submit">
									<i className="fa-solid fa-magnifying-glass"></i>
								</button>
							</form>
						</div>
					</div>
					<hr />
					<div id="walletTable"></div>
					<table className="table table-responsive">
						<thead>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Price</th>
								<th scope="col">Tradable Qty</th>
								<th scope="col">In Order Qty</th>
								<th scope="col">Total</th>
								<th scope="col">Action</th>
							</tr>
						</thead>
						<tbody>
							{isLoading ? (
								<tr>
									<td colSpan="6">
										<div className="loader-container">
											<div className="spinner"></div>
										</div>
									</td>
								</tr>
							) : (
								""
							)}
							{!isLoading &&
								filteredWallet?.map((wlt, index) => (
									<tr key={index}>
										<td className="name">
											<div className="logo">
												<div className="logoImage">
													<img
														src={`/asset/icon/${wlt.asset.toLowerCase()}.png`}
													/>
												</div>
												<div className="coinName">
													<p className="shortName">{wlt.asset}</p>
												</div>
											</div>
										</td>
										<td className="cost">
											<p className="coinCost">1</p>
											<span className="cost">{wlt.price}</span>
										</td>
										<td className="coinCost">
											<p className="numCoins">
												{parseFloat(wlt.free)}
											</p>
										</td>
										<td className="coinCost">
											<p className="numCoins">
												{parseFloat(wlt.locked)}
											</p>
										</td>
										<td className="coinCost">
											<span className="totalPrice">{wlt.amount_in_usdt}</span>
										</td>

										<td>
											<a
												className="hvr-sweep-to-right"
												data-bs-toggle="modal"
												data-bs-target="#exampleModal"
												onClick={isOpen}
												href="#"
											>
												{" "}
												<button type="button" className="btn">
													Trade
												</button>
											</a>
										</td>
									</tr>
								))}
						</tbody>
					</table>
					<div className="pagination">{searchQuery ? "" : spanElements}</div>
				</div>
			</div>
		</Base>
	);
};
