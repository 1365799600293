import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
//css
import './App.css';
import "react-toastify/dist/ReactToastify.css";


// routes
import Login from './components/loginForm/login';
import NoPage from './components/NoPage';
import Wallet from './components/wallet/wallet';
import SaveApiKey from "./components/apiForm/api";
import History from "./components/history/history";
import Spot from "./components/spotTrading/spotTrading";
import DetailedHistory from "./components/history/detailedHistory";
import Calculation from "./components/tradeCalculation/calculation";
import Dashboard from "./components/dashboard/dashboard";
// import PrivateRoute from "./components/PrivateRoute";

//admin
import AdminLogin from "./components/admin/login";
import AdminDashboard from "./components/admin/dashboard";
import Users from "./components/admin/users";
import UserDetail from "./components/admin/users/detail";
import UserOrders from "./components/admin/users/orders";
import UserOrderDetails from "./components/admin/users/orders/details";
import React from 'react';
import ForgotPassword from './components/loginForm/forgotPassword';
import ResetPassword from './components/loginForm/resetPassword';

function App() {
  const user = useSelector((state) => state.loginSlice);
  const admin = useSelector((state) => state.adminLoginSlice);
  
  return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route path="/admin">
						<Route
							index
							element={
								Object.keys(admin.login).length ? (
									<AdminDashboard />
								) : (
									<AdminLogin />
								)
							}
						/>
						<Route path="users">
							<Route index element={<Users />} />
							<Route path=":id" element={<UserDetail />} />
							<Route path=":id/orders" element={<UserOrders />} />
							<Route
								path=":id/orders/:orderId"
								element={<UserOrderDetails />}
							/>
						</Route>
					</Route>
					<Route path="/">
						<Route
							index
							element={
								Object.keys(user.login).length ? (
									(user.login.api_keys && user.login.api_keys_status == "VALID") ? (
										<Wallet />
									) : (
										<SaveApiKey />
									)
								) : (
									<Login />
								)
							}
						/>
					</Route>
					{user.login?.api_keys ? (
						<React.Fragment>
							<Route path="/history" element={<History />} />
							<Route path="/spot" element={<Spot />} />
							<Route path="/history/:id" element={<DetailedHistory />} />
							<Route path="/calculation" element={<Calculation />} />
						</React.Fragment>
					) : (
						<Route element={<Navigate to="/" />} />
					)}
					
					<Route path="/dashboard" element={<Dashboard />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/reset-password" element={<ResetPassword />} />
					<Route path="*" element={<NoPage />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
