import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from "redux-persist";
import LoginReducer from './slices/LoginSlice';
import AdminLoginReducer from './slices/admin/LoginSlice';
import storage from "redux-persist/lib/storage";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import SaveApiKeyReducer from './slices/SaveApiKeySlice';
import WalletSlice from './slices/WalletSlice';
import OrdersSlice from './slices/OrdersSlice';
import OrderDetailsSlice from './slices/OrderDetailsSlice';
import CoinsSlice from './slices/CoinsSlice';
import UsersSlice from './slices/admin/UsersSlice';
import UserOrderSlice from "./slices/admin/OrdersSlice";

const rootPersistConfig = {
	key: "root",
	storage,
};

const loginPersistConfig = {
	key: "login",
	storage,
};
const adminLoginPersistConfig = {
	key: "adminLogin",
	storage,
};
// const rootReducer = combineReducers({
// 	login: persistReducer(loginPersistConfig, LoginReducer),
//   order: []
// });

// const persistedReducer = persistReducer(rootPersistConfig, rootReducer);



export const store = configureStore({
	// reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
	reducer: {
		loginSlice: persistReducer(loginPersistConfig, LoginReducer),
		saveApiKeySlice: SaveApiKeyReducer,
		walletSlice: WalletSlice,
		orderSlice: OrdersSlice,
    orderDetailSlice: OrderDetailsSlice,
    coinsSlice: CoinsSlice,
		adminLoginSlice: persistReducer(adminLoginPersistConfig, AdminLoginReducer),
    usersSlice: UsersSlice,
    usersOrderSlice: UserOrderSlice,
	},
});