import React from "react";
import Navbar from "./navbar/navbar";
import Sidebar from "./sidebar/sidebar";
import "../asset/scss/user.scss";
import { useLocation } from "react-router";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
//import Modal from './Modal';
import { ToastContainer } from "react-toastify";
import { margin } from "@mui/system";

const currentTab = (location, currentPath) => {
  if (currentPath === "/" && location.pathname !== currentPath) {
    return "";
  }
  if (location.pathname.indexOf(currentPath) !== -1) {
    return "active";
  }
};

export default ({ history, children }) => {
  const { login } = useSelector((state) => state.loginSlice);

  const location = useLocation();

  return (
    <>
      {login?.isAuth ? (
        <>
          <Navbar />
          <Sidebar currentTab={currentTab} />
          <ToastContainer />
          <div className="content">{children}</div>
        </>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};
