import React from "react"
import "./index.scss"
import {Link, useLocation} from 'react-router-dom';


export default function (props) {
  const location = useLocation();
  const [OpenModal, setOpenModal] = React.useState(false);

  const isOpen = () => {
    setOpenModal(true);
  }

  const closed = () => {
    setOpenModal(false);
  }

  const cancelButn = {
    position: "fixed",
    cursor: 'pointer',
    backgroundColor: "rgb(78, 135, 108)",
    zIndex: "1111",
    left: "227px",
    top: "82px",
    fontSize: "20px",
    color: "white",
    padding: "0px 5px",
    transition: "all 0.5s ease",
  }
    return (
			<>
				<div id="SidebarComponent">
					<input type="checkbox" id="check" />
					<label htmlFor="check">
						<i className="fas fa-bars" id="btn"></i>
						<i className="fas fa-times" id="cancel"></i>
					</label>
					<div className="sidebar">
						<header>My App</header>
						<ul>
							<li className={props.currentTab(location, "/admin")}>
								<Link className="hvr-sweep-to-right" to="/admin">
									<i className="fa-solid fa-wallet"></i>
									<span>Dashboard</span>
								</Link>
							</li>
							<li className={props.currentTab(location, "/admin/users")}>
								<Link className="hvr-sweep-to-right " to="/admin/users">
									<i className="fa-solid fa-clock-rotate-left"></i>
									<span>Users</span>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</>
		);
}