import React, { useEffect } from "react";
import { postLogin } from "../../slices/LoginSlice";
import "./login.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import Axios from "../../utils/Axios";

export default function () {
	const [IsLoading, setIsLoading] = React.useState(false);
  
  const queryString = window.location.search;

	const urlParams = new URLSearchParams(queryString);

	const token = urlParams.get("token");
  
	const dispatch = useDispatch();
	const location = useLocation();

	const handleSubmit = (event) => {
		event.preventDefault();
		setIsLoading(true);
		
		let password = event.target.elements.password.value;
		let password2 = event.target.elements.password2.value;

    if(password != password2){
      toast.error("Password and Confirm Password does not match.");
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      return false;
    }
    let data = {
      token,
      password,
      password2
    }
		Axios.post("/reset-password", data)
			.then((response) => {
				toast.success(response.data.message);
				setIsLoading(false);
        setTimeout(() => {
          window.location.href="/";
        }, 1000);
			})
			.catch((error) => {
        toast.error(error.response.data.message)
        setIsLoading(false);
      });
	};

	const LoadingButton = () => {
		return <span className="spinner-grow spinner-grow-sm"></span>;
	};

	return (
		<div className="container-fluid" id="loginForm">
			<ToastContainer />
			<form onSubmit={handleSubmit}>
				<div className="formHeading">
					<h2 className="heading">Reset Password</h2>
				</div>

				<div className="mb-3 field">
					<label htmlFor="exampleInputEmail1" className="form-label">
						Password
					</label>
					<input
						type="password"
						className="form-control"
						id="exampleInputPassword"
						aria-describedby="passwordHelp"
						name="password"
						required
					/>
				</div>
				<div className="mb-3 field">
					<label htmlFor="exampleInputEmail1" className="form-label">
						Confirm Password
					</label>
					<input
						type="text"
						className="form-control"
						id="exampleInputPassword2"
						aria-describedby="password2Help"
						name="password2"
						required
					/>
				</div>
				<div className="loginButton d-grid">
					<button
						type="submit"
						className="btn"
						disabled={IsLoading ? true : false}
					>
						{IsLoading ? <LoadingButton /> : "Change"}
					</button>
				</div>
			</form>
		</div>
	);
}
