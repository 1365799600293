import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../utils/Axios";
import { toast } from "react-toastify";
import LoginSlice, {setApiKey} from "./LoginSlice";

const initialState = {
  keys: {},
  isLoading: false,
  errors: {}
}

export const saveApiKeys = createAsyncThunk(
	"save-api-keys",
	async (payload, thunkAPI) => {
		// console.log(payload);
		try {
			const res = await Axios.post("/save_api_keys",
				payload
			);

			return res.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

const SaveApiKeySlice = createSlice({
	name: "saveApiKeySlice",
	initialState,
	reducers: {
		saveApiKeyss: (state) => {
			state.keys = {};
		},
	},
	extraReducers: {
    // [setApiKey]: (state) => {
    //   state.login.api_keys = true;
    // },
		[saveApiKeys.pending]: (state) => {
			state.isLoading = true;
		},
		[saveApiKeys.fulfilled]: (state, action) => {
			state.isLoading = false;
      toast.success("Keys Saved.");
      state.keys = {};
		},
		[saveApiKeys.rejected]: (state, action) => {
			state.isLoading = false;
      state.errors = action.payload;
			toast.error(action.payload.message);
		},
	},
});

export const { saveApiKeyss } = SaveApiKeySlice.actions;
export default SaveApiKeySlice.reducer;