import React, {useEffect} from "react";
import { postAdminLogin } from "../../../slices/admin/LoginSlice";
import "./index.scss";
import {useDispatch, useSelector} from 'react-redux';
import { useLocation } from "react-router";
import { ToastContainer, toast } from "react-toastify";

export default function() {

  const dispatch = useDispatch();
  const location = useLocation();

  // useEffect(() => {
  //   toast('hello! i am working');
  // }, [])

  const admin = useSelector(state => state.adminLoginSlice);
  const errors = admin?.errors;

  useEffect(() => {
    console.log();
    // if(admin.login){
    //   if(admin.login.api_keys){
    //     //redirect to wallet
    //     location.redirect('/save-api-keys');
    //   } else {
    //     //redirect to save api keys form
    //     location.redirect('/wallet');
    //   }
    // }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(postAdminLogin({email: event.target.email.value, password: event.target.password.value}));
  }

  const LoadingButton = () => {
    return <span className="spinner-grow spinner-grow-sm"></span>;
  }

    return (
			<div className="container-fluid" id="loginForm">
				<ToastContainer/>  
				<form onSubmit={handleSubmit}>
					<div className="formHeading">
						<h2 className="heading">Login</h2>
					</div>

					<div className="mb-3 field">
						<label htmlFor="exampleInputEmail1" className="form-label">
							Email address
						</label>
						<input
							type="email"
							className="form-control"
							id="exampleInputEmail1"
							aria-describedby="emailHelp"
							name="email"
							required
						/>
					</div>
					<div className="mb-3 field">
						<label htmlFor="exampleInputPassword1" className="form-label">
							Password
						</label>
						<input
							type="password"
							className="form-control"
							name="password"
							id="exampleInputPassword1"
							required
						/>
					</div>

					<div className="loginButton d-grid">
						<button
							type="submit"
							className="btn"
							disabled={admin.isLoading ? true : false}
						>
							{admin.isLoading ? <LoadingButton /> : "Submit"}
						</button>
					</div>
				</form>
			</div>
		);
}